/**
 * Dependencies
 */
import {polaris} from "./config";
import {errorsAPIs} from "./errors";
import {pageAPIs} from "./page";
import {userAPIs} from "./user";
import {profileAPIs} from "./profile";
import {adminAPIs} from "./admin";
import {settingsAPIs} from "./settings";
import {analyticsAPIs} from "./analytics";
import {filesAPIs} from "./files";


/**
 * Global variables
 */
let localStorage = window.localStorage;

let geoInfo, asyncInterval, wysiwyg;

const doc        = document.querySelector(".doc");
const partials   = document.querySelector(".partials");
const spiner     = document.querySelector('#page-spiner');
const dataAPI    = partials.dataset.api;
const dataSource = partials.dataset.source;
const dataTimer  = partials.dataset.timer;
const datajQuery = partials.dataset.jquery;
const dataMode   = partials.dataset.mode;
const components = ['header', 'upper', 'nav', 'main', 'aside', 'lower', 'footer'];

// Language dir
export const dataDir  = partials.dataset.dir;
export const langCode = document.querySelector('html').getAttribute("lang");


/**
 * Initial load
 */
polaris.loaded(() => {
    // Load all APIs
    loadAPIs();
    
    // Load default APIs
    if (dataMode === "jinja") {
        defaultAPIs(dataAPI, dataTimer);
    }

    // Load public custom APIs
    customAPIs();

    // Load spa
    const dataApp = partials.dataset.app;

    loadPage(dataApp, dataSource, dataAPI, () => customAPIs());
});


/**
 * Global APIs
 */
export const loadAPIs = () => {
    // Polaris defaults
    polaris.init();

    // Error App APIs
    errorsAPIs();

    // Public App APIs
    if (document.querySelector('#app-page')) pageAPIs();

    // System App APIs
    systemAPIs();

    // Generic APIs
    genericAPIs();
};


/**
 * System App APIs
 */
const systemAPIs = () => {
    // Load User APIs
    if (document.querySelector('#app-user')) userAPIs();

    // Load Profile APIs
    if (document.querySelector('#app-profile')) profileAPIs();

    // Load Settings APIs
    if (document.querySelector('#app-settings')) settingsAPIs();

    // Load Admin APIs
    if (document.querySelector('#app-admin')) adminAPIs();

    // Load Analytics APIs
    if (document.querySelector('#app-analytics')) analyticsAPIs();

    // Load Files APIs
    if (document.querySelector('#app-files')) filesAPIs();
};


/**
 * Generic APIs
 */
const genericAPIs = () => {
    // Load skin API
    skinAPI(dataAPI);

    // Handlle window on scroll
    scrollHandler(() => {});

    // Navigator
    toggleNav();

    // Fullscreen toggle
    toggleScreen();

    // Boxed & fluid
    toggleLayout();

    // Navigation active class
    websiteNav();

    // Show field
    fieldShow();

    // Hide field
    fieldHide();

    // Logout API
    logoutAPI();

    // Set Summernote
    setSummernote();
};


/**
 * Toggles the skin (light/dark)
 */
const skinAPI = (api) => {
    // Default variables
    const tgl = document.querySelector("#change-skin");
    const doc = document.querySelector(".doc");
    let skin;
    
    // Has skin storage
    if (localStorage.getItem("doc-skin")) {
        skin = localStorage.getItem("doc-skin");

        // Light skin
        if (skin === 'light') {
            // Toggler
            if (tgl) {
                tgl.querySelector("i").classList.remove("fa-sun");
                tgl.querySelector("i").classList.add("fa-moon");
            }

            // Document
            doc.classList.remove("doc-dark");
            doc.classList.add("doc-light");
        }
        // Dark skin
        else if (skin === 'dark') {
            // Toggler
            if (tgl) {
                tgl.querySelector("i").classList.remove("fa-moon");
                tgl.querySelector("i").classList.add("fa-sun");
            }

            // Document
            doc.classList.remove("doc-light");
            doc.classList.add("doc-dark");
        }
    }
    // No skin storage
    else {
        // Check toggler
        if (tgl) {
            // Light skin
            if (doc.classList.contains("doc-light")) {
                // Toggler
                tgl.querySelector("i").classList.remove("fa-sun");
                tgl.querySelector("i").classList.add("fa-moon");
            }
            // Dark skin
            else if (doc.classList.contains("doc-doc")) {
                // Toggler
                tgl.querySelector("i").classList.remove("fa-moon");
                tgl.querySelector("i").classList.add("fa-sun");
            }
        }
    }

    // Check toggler
    if (tgl) {
        // Handle click
		tgl.onclick = () => {
			// Light skin
			if (doc.classList.contains("doc-light")) {
				// Document
				doc.classList.remove("doc-light");
				doc.classList.add("doc-dark");

				// Toggler
				tgl.querySelector("i").classList.remove("fa-moon");
				tgl.querySelector("i").classList.add("fa-sun");

				// Update the skin
				skin = 'dark';
			}
			// Dark skin
			else {
				// Document
				doc.classList.remove("doc-dark");
				doc.classList.add("doc-light");

				// Toggler
				tgl.querySelector("i").classList.remove("fa-sun");
				tgl.querySelector("i").classList.add("fa-moon");

				// Update the skin
				skin = 'light';
			}

            // Set skin storage
            localStorage.setItem("doc-skin", skin);

            // Check charts
            if (document.querySelectorAll('.chart-js').length > 0) {
                // reload the page
                document.querySelector('.spa-link.active').click();
            }

            // // Check template mode
            // if (dataMode == 'jinja') {
            //     // Skin API
            //     fetch(api, {
            //         method: 'put',
            //         headers: new Headers({
            //             'Content-Type': 'application/json'
            //         }),
            //         body: JSON.stringify({
            //             skin_api: true,
            //             skin:     skin
            //         })
            //     });
            // }

            // Prevent default behavior
            return false;
		};
	}
};


/**
 * Toggles layout (boxed & fluid)
 */
const toggleLayout = () => {
    // Default variables
    const tgl = document.querySelector("#toggle-layout");
    const doc = document.querySelector(".doc");
    let layout;

    // Check toggler
    if (tgl) {
        // Has layout storage
        if (localStorage.getItem("doc-layout")) {
            layout = localStorage.getItem("doc-layout");

            // Boxed
            if (layout === 'boxed') {
                // Toggler
                tgl.querySelector("i").classList.remove("fa-compress");
                tgl.querySelector("i").classList.add("fa-expand");

				// Document
				doc.classList.add("doc-boxed");
            }
            // Fluid
            else if (layout === 'fluid') {
                // Toggler
                tgl.querySelector("i").classList.remove("fa-expand");
                tgl.querySelector("i").classList.add("fa-compress");

				// Document
				doc.classList.remove("doc-boxed");
            }
        }
        // No layout storage
        else {
            // Boxed
            if (doc.classList.contains("doc-boxed")) {
                // Toggler
                tgl.querySelector("i").classList.remove("fa-compress");
                tgl.querySelector("i").classList.add("fa-expand");
            }
            // Fluid
            else {
                // Toggler
                tgl.querySelector("i").classList.remove("fa-expand");
                tgl.querySelector("i").classList.add("fa-compress");
            }
        }

        // Handle click
		tgl.onclick = () => {
			// Boxed
			if (doc.classList.contains("doc-boxed")) {
				// Document
				doc.classList.remove("doc-boxed");

				// Toggler
                tgl.querySelector("i").classList.remove("fa-expand");
                tgl.querySelector("i").classList.add("fa-compress");

				// Update the layout
				layout = 'fluid';
			}
			// Fluid
			else {
				// Document
				doc.classList.add("doc-boxed");

				// Toggler
                tgl.querySelector("i").classList.remove("fa-compress");
                tgl.querySelector("i").classList.add("fa-expand");

				// Update the layout
				layout = 'boxed';
			}

            // Set layout storage
            localStorage.setItem("doc-layout", layout);

            // // Check template mode
            // if (dataMode == 'jinja') {
            //     // Layout API
            //     fetch(api, {
            //         method: 'put',
            //         headers: new Headers({
            //             'Content-Type': 'application/json'
            //         }),
            //         body: JSON.stringify({
            //             layout_api: true,
            //             layout:   layout
            //         })
            //     });
            // }

            // Prevent default behavior
            return false;
		};
	}
};


/**
 * @desc Handles animated elements
 */
const animatedHandler = () => {
    const animated = document.querySelector('.scroll-animation');
    if (animated) {
        polaris.animated('.scroll-animation', 'top');
    }
};


/**
 * @desc Handles navigated elements
 */
const navigatedHandler = () => {
    const navigator = document.querySelector('.navigator');
    const navigated = document.querySelector('.navigated');
    if (navigator && navigated) {
        polaris.navigated('.navigator', '.navigated', 'active', 64);
    }
};


/**
 * @desc Handles fixed topbar on scroll
 */
const topbarHandler = () => {
    const topbar = document.querySelector('#topbar-fixed');
    if (topbar) {
        const scroll = window.scrollY;
        const height = topbar.dataset.height;
        
        if (scroll >= height) {
            topbar.classList.add('topbar-fixed');
        }
        else {
            topbar.classList.remove('topbar-fixed');
        }
    }
};


/**
 * @desc Handles window on scroll for animations and navigators
 */
export const scrollHandler = (customScrolls) => {
    // Check app
    if (partials.dataset.app === 'page' || partials.dataset.app === 'cdn') {
        // On load
        animatedHandler();
        navigatedHandler();
        topbarHandler();
        customScrolls();
        
        // On scroll
        window.onscroll = () => {
            animatedHandler();
            navigatedHandler();
            topbarHandler();
            customScrolls();
        };
    }
};


/**
 * Sets website navigation active class
 */
export const websiteNav = (selector='.website-nav', activeClass='active') => {
    const nodes = document.querySelectorAll(selector);
    if (nodes.length > 0) {
        let URL  = polaris.href();        // window.location.origin;
        let lang = null;
        
        // Remove hash
        URL = polaris.removeAfter(URL, "#", true);

        // Check language
        if (document.querySelector("html").getAttribute("lang")) {
            lang = document.querySelector("html").getAttribute("lang");
        }
        
        // Loop website navigations
        nodes.forEach(node => {
            for (let i = 0; i < node.querySelectorAll('a').length; i++) {
                // Find inner a
                const index = node.querySelectorAll('a')[0];
                const elem  = node.querySelectorAll('a')[i];
                let href    = elem.getAttribute("href");

                // Remove hash
                href = polaris.removeAfter(href, "#", true);

                // Apply unicode
                href = encodeURI(href)

                // Remove previous active class
                elem.classList.remove(activeClass);
                
                // Add active class to index page
                if (URL == "/" || URL == "/" + lang + "/") {
                    index.classList.add(activeClass);

                // Add active class to the current page nav
                } else if (href == URL || "/" + lang + href == URL) {
                    elem.classList.add(activeClass);
                }
            }
        });
    }
};


/**
 * Show field
 */
const fieldShow = () => {
    let nodes = document.querySelectorAll('.field_show');

    if (nodes) {
        nodes.forEach((node) => {
            node.onclick = () => {
                const icon = node.querySelector("i");
                const input = node.parentElement.querySelector(".field");
                const type = input.dataset.type;

                if (input) {
                    if (input.getAttribute("type") === 'password') {
                        icon.classList.remove("fa-eye-slash");
                        icon.classList.add("fa-eye");
                        input.setAttribute("type", type);
                    }
                    else {
                        icon.classList.remove("fa-eye");
                        icon.classList.add("fa-eye-slash");
                        input.setAttribute("type", "password");
                    }
                }
            };
        });
    }
};


/**
 * Hide field
 */
const fieldHide = () => {
    let nodes = document.querySelectorAll('.field_hide');

    if (nodes) {
        nodes.forEach((node) => {
            node.onclick = () => {
                const icon = node.querySelector("i");
                const input = node.parentElement.querySelector(".field");
                const type = input.dataset.type;

                if (input) {
                    if (input.getAttribute("type") === 'password') {
                        icon.classList.remove("fa-eye-slash");
                        icon.classList.add("fa-eye");
                        input.setAttribute("type", type);
                    }
                    else {
                        icon.classList.remove("fa-eye");
                        icon.classList.add("fa-eye-slash");
                        input.setAttribute("type", "password");
                    }
                }
            };
        });
    }
};


/**
 * Logout API
 */
const logoutAPI = () => {
    // SPA logout
    let spaLogout = document.querySelector('.spa-logout');
    if (spaLogout) {
        spaLogout.onclick = () => {
            // Disable the link
            spaLogout.classList.add("disabled");

            // Produce the logout action
            let action = spaLogout.getAttribute('href') + `?spa=true&geo=${geoInfo}`;

            // Logout API
            fetch(action)
            .then(response => response.json())
            .then(result => {
                if (result.status == 'success') {
                    // Alert message
                    polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, "", true, 750, 0);

                    // Update the dynamic link
                    let link = document.querySelector("#dynamic-link");
                    link.setAttribute('href', result.url);

                    // Trigger the click event
                    link.click();
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Set Summernote wysiwyg editor
 */
export const setSummernote = (delay=250) => {
    // Check jQuery
    if (datajQuery !== '0') {
        // Clear wysiwyg timeout
        clearTimeout(wysiwyg);
    
        // Summernote (full)
        wysiwyg = setTimeout(() => {
            $('.summernote').summernote({
                toolbar: [
                    // [groupName, [list of button]]
                    ['style', ['style']],
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['strikethrough', 'superscript', 'subscript']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['height', ['height']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'hr', 'picture', 'video']],
                    ['view', ['fullscreen', 'codeview']],
                    ['misc', ['undo', 'redo', 'help']],
                ]
            });
    
            // // summernote.change
            // $('.summernote').on('summernote.change', function() {
            //     $(this).summernote('reset');
            // });
        }, delay);
    
        // Summernote (mini)
        wysiwyg = setTimeout(() => {
            $('.summernote-mini').summernote({
                toolbar: [
                    // [groupName, [list of button]]
                    ['style', ['style']],
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['font', ['strikethrough', 'superscript', 'subscript']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['height', ['height']],
                    ['view', ['codeview']],
                    ['misc', ['undo', 'redo']],
                ]
            });
        }, delay);
    }
};


/**
 * @desc Sets default page history & APIs
 */
const defaultAPIs = (api, timer = 30) => {
    // Synchronous APIs
    syncAPIs(api);

    // Asynchronous APIs
    asyncAPIs(api, timer);

    // Default variables
    const pageURL = polaris.href();
    let pageState = polaris.replace(pageURL, '/', '_');

    // History not exists
    if (window.history.length == 0) {
        // Set the default page history
        window.history.pushState({app: pageState, url: pageURL}, '', pageURL);
    } 
    // History exists
    else {
        try {
            // Check the current history state
            if (window.history.state.app !== pageState) {
                // Replace the history for new page
                window.history.replaceState({app: pageState, url: pageURL}, '', pageURL);
            }
        }
        catch {
            // Replace the history for new page
            window.history.replaceState({app: pageState, url: pageURL}, '', pageURL);
        }
    }

    // Check the window hash
    let pageHash = window.location.hash;
    if (pageHash) {
        if (document.querySelector(pageHash)) {
            document.querySelector(pageHash).scrollIntoView();
        }
    }
    else {
        document.querySelector('.partials').scrollIntoView();
    }

    // Window hash listener
    window.onhashchange = () => {
        const pageURL = polaris.href();
        let pageState = polaris.replace(pageURL, '/', '_');

        // Check the current history state
        if (!window.history.state) {
            // Replace the history for new hash
            window.history.replaceState({app: pageState, url: pageURL}, '', pageURL);
        }
    }
};


/**
 * @desc Synchronous APIs
 */
const syncAPIs = (api, url=null) => {
    let pageURL = null;

    // Check URL
    if (url) {
        pageURL = url;
        geoInfo = localStorage.getItem("geo-info");

        // User Visits API
        visitsAPI(api, geoInfo, pageURL);
    }
    else {
        pageURL = polaris.href();

        // Find User geolocation info
        polaris.json("/statics/json/tz-cities-countries.json")
        .then((result) => {
            let timeZone, region, city, country;
            
            // Check Intl API
            if (Intl) {
                // Find time zone
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                // Process time zone
                let tzArr = timeZone.split("/");
                
                // Find region
                region = tzArr[0];

                // Find city
                city = tzArr[tzArr.length - 1];
            }

            // Find country
            for (let row of result) {
                if (row['city'] == city) {
                    country = row['country'];
                }
            }

			// Check timeZone
			if (!timeZone) {
				timeZone = 'Unknown';
			}
			
			// Check country
			if (!country) {
				region   = 'Unknown';
				country  = 'Unknown';
				city     = 'Unknown';
			}
            
            // Geolocation info
            let data = {
                "time_zone": timeZone,
                "region"   : region,
                "country"  : country,
                "city"     : city
            }

            // Update geolocation storage
            localStorage.setItem("geo-info", JSON.stringify(data));

            // Update geolocation variable
            geoInfo = localStorage.getItem("geo-info");
        })
        .then(() => {
            // Visits API
            visitsAPI(api, geoInfo, pageURL);
        });

        // Upload Settings API
        uploadSettingsAPI(api);
    }
};


/**
 * @desc Asynchronous APIs
 */
const asyncAPIs = (api, timer) => {
    // Clear previous interval
    clearInterval(asyncInterval);

    // Asynchronous Interval
    asyncInterval = setInterval(() => {
        let pageURL = polaris.href();
        geoInfo = localStorage.getItem("geo-info");
        
        // Online Users API
        onlinesAPI(api, geoInfo, pageURL);
        
    }, timer * 1000);
};


/**
 * @desc User Visits API
 */
const visitsAPI = (api, geoInfo, pageURL) => {
    fetch(api, {
        method: 'put',
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            visits_api: true,
            geo:        geoInfo,
            url:        pageURL
        })
    });
};


/**
 * @desc Upload Settings API
 */
const uploadSettingsAPI = (api) => {
    fetch(api, {
        method: 'put',
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            upload_settings: true
        })
    })
    .then(response => response.text())
    .then(result => {
        // Update upload storage
        localStorage.setItem("upload-settings", result);
    });
};



/**
 * @desc Online Users API
 */
const onlinesAPI = (api, geoInfo, pageURL) => {
    fetch(api, {
        method: 'put',
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            onlines_api: true,
            geo:      geoInfo,
            url:      pageURL
        })
    });
};


/**
 * @desc Loads SPA pages
 * 
 * @param app: string     -- The app name 
 * @param source: string  -- The template source
 * @param api: string     -- The global api URL
 * @param fn: function    -- The callback function
 * 
 * @returns object
 */
export const loadPage = function (app, source, api, fn=null, initial=true) {
    // Set a new spa promise
    let promise = new Promise((resolve, reject) => {
        // Default elements
        let final = null;
        let nodes = document.querySelectorAll('.spa-link');


        /**
         *  Initial Load
         */
        if (initial && app != 'errors') {
            // Set constants
            setConstants();

            // Produce Controller URL
            let URL = polaris.href();
            let get = URL.split('?')[1];
                    
            URL = URL.split('?')[0];
            URL = polaris.removeAfter(URL, "#", true);

            (get) ? get = '&' + get : get = '';

            URL = `${URL}?initial=${app + get}`;

            // Fetch page data
            fetch(URL)
            .then(response => response.json())
            .then(result => {
                // Set partials storage
                setPartials(app, result);

                // Resolve the promise
                resolve('Document initialy loaded!');
            });
        }


        /**
         *  Nodes listener on click
         */
        if (nodes) {
            nodes.forEach(node => {
                node.onclick = function() {
                    // Inactivate the node
                    node.classList.add("inactive");

                    // Page progress
                    const pageLoader   = document.querySelector("#page-loader").querySelector(".progress");
                    const pageProgress = document.querySelector("#page-loader").querySelector(".progress--bar");

                    // Page URL
                    const pageURL = node.getAttribute("href");

                    // Analize URL
                    let path = pageURL.split('?')[0];
                    let get  = pageURL.split('?')[1];

                    // Check the path
                    path = polaris.removeAfter(path, "#", true);

                    // Check the get method
                    (get) ? get = '&' + get : get = '';

                    // Check custom
                    const custom = (partials.dataset.custom == "1") ? '&custom=1' : '';

                    // Final URL
                    let URL = `${path}?spa=${app + custom + get}`;

                    // Set the page progress
                    polaris.animation(pageLoader, "fadeIn");
                    polaris.animation(pageProgress, pageProgress.dataset.enter);

                    // Fetch page data
                    fetch(URL)
                    .then(response => response.json())
                    .then(result => {
                        // Create the redirect condition
                        let redirect = Boolean(
                            // Another language request
                            result.language != document.querySelector("html").getAttribute("lang") ||

                            // Error page
                            result.error ||

                            // Wrong SPA request
                            result.wrong_spa_request
                        );

                        // Check redirect condition
                        if (redirect) {
                            // Resolve the promise
                            resolve(result);

                            // Redirect to the URL
                            polaris.redirect(pageURL);
                        }
                        
                        // Correct SPA request
                        else {
                            // Set page content
                            setContent(app, source, result);

                            // Update partials storage
                            setPartials(app, result);

                            // Check updates
                            if (result.updates) updateContent(result);

                            // Prepare the page history state
                            let pageState = polaris.replace(pageURL, '/', '_');
    
                            // Check the current history state
                            if (window.history.state.app !== pageState) {
                                window.history.pushState({app: pageState, url: pageURL}, '', pageURL);
                            }
                            else {
                                window.history.replaceState({app: pageState, url: pageURL}, '', pageURL);
                            }
                        }

                        // Set the final result
                        final = result;
                    })
                    .then(() => {
                        // Load APIs
                        loadAPIs();

                        // Invoke callback function
                        if (fn) fn();
                        
                        // Unset the page progress
                        polaris.animation(pageLoader, "fadeOut").then(() => {
                            polaris.animation(pageProgress, "");
                        });

                        // Check the window hash
                        let pageHash = window.location.hash;
                        if (pageHash) {
                            if (document.querySelector(pageHash)) {
                                document.querySelector(pageHash).scrollIntoView();
                            }
                        }
                        else {
                            document.querySelector('.partials').scrollIntoView();
                        }

                        // Activate the node
                        node.classList.remove("inactive");
                        
                        // Resolve the promise
                        resolve(final);
                    })
                    .then(() => {
                        // Function recursion
                        loadPage(final.app, final.source, api, fn, initial=false);
                        
                        // Synchronous APIs
                        syncAPIs(api, pageURL);
                    });

                    // Prevent default behavior
                    return false;
                };
            });
        }


        /**
         * API load on history change
         */
        window.onpopstate = event => {
            // History available
            if (event.state) {
                // Page history dataset
                const pageURL = event.state.url;

                // Analize URL
                let path = pageURL.split('?')[0];
                let get  = pageURL.split('?')[1];

                // Check the path
                path = polaris.removeAfter(path, "#", true);

                // Check the get method
                (get) ? get = '&' + get : get = '';

                // Check custom
                const custom = (partials.dataset.custom == "1") ? '&custom=1' : '';

                // Final URL
                let URL = `${path}?spa=${app + custom + get}`;

                // Page progress
                const pageLoader   = document.querySelector("#page-loader").querySelector(".progress");
                const pageProgress = document.querySelector("#page-loader").querySelector(".progress--bar");

                // Set the page progress
                polaris.animation(pageLoader, "fadeIn");
                polaris.animation(pageProgress, pageProgress.dataset.enter);

                // Fetch page data
                fetch(URL)
                .then(response => response.json())
                .then(result => {
                    // Create the redirect condition
                    let redirect = Boolean(
                        // Another language request
                        result.language != document.querySelector("html").getAttribute("lang") ||

                        // Error page
                        result.error ||

                        // Wrong SPA request
                        result.wrong_spa_request
                    );

                    // Check redirect condition
                    if (redirect) {
                        // Resolve the promise
                        resolve(result);

                        // Redirect to the URL
                        polaris.redirect(pageURL);
                    }
                    
                    // Correct SPA request
                    else {
                        // Set page content
                        setContent(app, source, result);

                        // Update partials storage
                        setPartials(app, result);

                        // Check updates
                        if (result.updates) updateContent(result);
                    }

                    // Set the final result
                    final = result;
                })
                .then(() => {
                    // Load APIs
                    loadAPIs();

                    // Invoke callback function
                    if (fn) fn();

                    // Unset the page progress
                    polaris.animation(pageLoader, "fadeOut").then(() => {
                        polaris.animation(pageProgress, "");
                    });

                    // Check the window hash
                    let pageHash = window.location.hash;
                    if (pageHash) {
                        if (document.querySelector(pageHash)) {
                            document.querySelector(pageHash).scrollIntoView();
                        }
                    }
                    else {
                        document.querySelector('.partials').scrollIntoView();
                    }

                    // Resolve the promise
                    resolve(final);
                })
                .then(() => {
                    // Function recursion
                    loadPage(final.app, final.source, api, fn, initial=false);

                    // Synchronous APIs
                    syncAPIs(api, pageURL);
                });
            } 
        };

    });

    // Return the promise
    return promise;
};


/**
 * @desc Sets constants local storage
 * 
 * @returns JSON object
 */
const setConstants = () => {
    fetch(dataAPI, {
        method: 'put',
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            constants_api: true
        })
    })
    .then(response => response.json())
    .then(result => {
        // Update constants storage
        localStorage.setItem("constants", JSON.stringify(result));
    });
};


/**
 * @desc Returns constant from constants local storage
 * 
 * @param text: string -- The text to translate
 * 
 * @returns string
 */
export const constant = (text) => {
    // English
    if (langCode == 'en') {
        return text;

    // Other languages
    } else {
        // Find result
        const result = JSON.parse(localStorage.getItem("constants"))[text];

        // Check result
        if (result) {
            return result;
        } else {
            return text;
        }
    }
};


/**
 * @desc Sets partials local storage
 * 
 * @param {string} app    -- The app name 
 * @param {object} result -- The fetch result 
 */
const setPartials = (app, result) => {
    // Partials data
    const data = {
        "header": result['header'],
        "upper":  result['upper'],
        "nav":    result['nav'],
        "main":   result['main'],
        "aside":  result['aside'],
        "lower":  result['lower'],
        "footer": result['footer']
    }

    // Update partials storage
    localStorage.setItem("partials", JSON.stringify(data));
};


/**
 * @desc Sets app components (partials) content
 * 
 * @param {string} app    -- The app name 
 * @param {string} source -- The template source 
 * @param {object} result -- The fetch result 
 */
const setContent = (app, source, result) => {
    const title = document.querySelector("title");
    const exCSS = document.querySelector('#ex-css');
    const exBP = document.querySelector('#ex-bp');

    // Update page SEO
    if (result.title) title.innerHTML = result.title;
    
    // New app requested
    if (result.new_app) {
        // Hide partials
        partials.style.setProperty('--animation-duration', '0s');
        partials.style.setProperty('animation-name', 'fadeOut');

        // Show spiner
        spiner.style.setProperty('animation-name', 'fadeIn');
        spiner.style.removeProperty('z-index');

        // Update app ID
        doc.setAttribute('id', 'app-' + result.app);

        // Update partials datasets
        partials.dataset.api    = result.api;
        partials.dataset.source = result.source;
        partials.dataset.timer  = result.timer;
        partials.dataset.app    = result.app;

        // Update external styles
        if (source != result.source) {
            const new_styles = polaris.replace(exCSS.getAttribute('href'), `/${source}/`, `/${result.source}/`);
            exCSS.setAttribute('href', new_styles);

            // Check app
            if (result.app == 'admin') {
                let dir;
                (dataDir === 'rtl') ? dir = '-' + dataDir : dir = '';
                exBP.setAttribute('href', `/statics/page/dist/css/styles${dir}.min.css`);
            } else {
                exBP.setAttribute('href', '/statics/aiomax/blueprint.css');
            }
        }

        // Update partials content
        // Custom content
        if (result.custom) {
            // Update partials content
            partials.innerHTML = result['custom'];

            // Set data-custom
            partials.dataset.custom = "1";
        } else {
            document.querySelector('.partials').innerHTML = result['partials'];
        }

        // View partials
        setTimeout(() => {
            // Show partials
            partials.style.setProperty('--animation-duration', '500ms');
            partials.style.setProperty('animation-name', 'fadeIn');
            // polaris.animation(partials, 'fadeIn');

            // Hide spiner
            spiner.style.setProperty('animation-name', 'fadeOut');
            spiner.style.setProperty('z-index', '-1');
            // polaris.animation(spiner, 'fadeOut');
        }, 500);
    }
    // Same app requested
    else {
        // Partials selector
        const partials = document.querySelector('.partials');

        // Partials storage
        const getPartials = JSON.parse(localStorage.getItem("partials"));

        // Custom content
        if (result.custom) {
            // Update partials content
            partials.innerHTML = result['custom'];

            // Set data-custom
            partials.dataset.custom = "1";
        }
        // Previous custom content
        else if (partials.dataset.custom == "1") {
            // Update partials content
            partials.innerHTML = result['partials'];

            // Unset data-custom
            partials.dataset.custom = "0";
        }
        // Normal content
        else {
            // Unset data-custom
            partials.dataset.custom = "0";

            // Update page components
            for (let x in components) {
                let node, nodeClass;
                if (result.app == 'admin') {
                    node = document.querySelector(`.admin-${components[x]}`);
                    nodeClass = `admin-${components[x]}`;
                }
                else {
                    node = document.querySelector(`.${components[x]}`);
                    nodeClass = components[x];
                }

                // Check node
                if (node) {
                    // Active component
                    if (result[components[x]]) {
                        if (result[components[x]] != getPartials[components[x]]) {
                            node.classList.remove("display-none");
                            try {node.outerHTML = result[components[x]];} 
                            catch {replaceHTML(node, result[components[x]]);}
                        }
                    }
                    // Inactive component
                    else {
                        // Empty content
                        node.innerHTML = "";

                        // Remove all classes
                        node.className = '';

                        // Add node & display-none classes
                        node.classList.add(nodeClass);
                        node.classList.add("display-none");
                    }
                }
            }
        }
    }
};


/**
 * @desc Updates components (partials) items
 * 
 * @param {object} result -- The fetch result 
 */
const updateContent = (result) => {
    // Loop update items
    for (let x in result.updates) {
        const item = document.querySelector(`.${x}`);

        // Check item
        if (item) {
            try {item.outerHTML = result.updates[x];} 
            catch {replaceHTML(item, result.updates[x]);}
        }
    }
};


/**
* @desc Creates and return a new form submition promise
* 
* @param {object}  form  -- The form object 
* @param {boolean} reset -- Reset the form on success 
* @param {string}  alert -- The alert color 
* 
* @returns object
*/
export const submitForm = function (form, reset=false, alert="") {
   // Set a new form promise
   const promise = new Promise((resolve, reject) => {
       let final = null;

       // Query form
       const submit = form.querySelector(`.submit`);
       const submitText = submit.innerHTML;

       // Prepare the submit button
       submit.setAttribute("disabled", true);
       submit.innerHTML = constant("Requesting...");
       
       // Fetch form date
       const action = form.getAttribute('action') + `?geo=${geoInfo}&lang=${langCode}`;
       const method = form.getAttribute('method');
       const data = new FormData(form);

       // Submit form data via fetch API
       fetch(action, {
           method: method,
           body:   data
       })
       .then(response => response.json())
       .then(result => {
           // Check the alert message
           if (alert !== null) {
               polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, alert, true, 750, 0);
           }

           // Everything is fine
           if (result.status === "success") {
               // Reset the form
               if (reset) form.reset();

               // Set the final result
               final = {
                   status: true,
                   data:   result
               }
           }

           // An error occoured
           else {
               // Set the final result
               final = {
                   status: false,
                   data:   result
               }
           }

           // Reset the submit button
           submit.removeAttribute("disabled");
           submit.innerHTML = submitText;
       })
       .then(() => {
           // Resolve the promise
           resolve(final);
       });
   });
   
   // Return the promise
   return promise;
}


/**
* @desc Creates and return a new fetch API promise
* 
* @param {object} parent -- The parent object 
* @param {object} submit -- The submit object 
* @param {object} data   -- The data to submit
* @param {string} alert  -- The alert color 
* 
* @returns object
*/
export const fetchAPI = function (parent, submit, data, alert="") {
   // Set a new promise
   const promise = new Promise((resolve, reject) => {
       let final = null;

       // Query selector
       const submitText = submit.innerHTML;

       // Prepare the submit button
       submit.setAttribute("disabled", true);
       submit.innerHTML = constant("Requesting...");
       
       // Fetch parent data
       const action = parent.getAttribute('data-action');
       const method = parent.getAttribute('data-method');

       // Submit data via fetch API
       fetch(action, {
           method: method,
           headers: new Headers({
               'Content-Type': 'application/json'
           }),
           body: JSON.stringify(data)
       })
       .then(response => response.json())
       .then(result => {
           // Check the alert message
           if (alert !== null) {
               polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, alert, true, 750, 0);
           }

           // Everything is fine
           if (result.status === "success") {
               // Set the final result
               final = {
                   status: true,
                   data:   result
               }
           }

           // An error occoured
           else {
               // Set the final result
               final = {
                   status: false,
                   data:   result
               }
           }

           // Reset the submit button
           submit.removeAttribute("disabled");
           submit.innerHTML = submitText;
       })
       .then(() => {
           // Resolve the promise
           resolve(final);
       });
   });
   
   // Return the promise
   return promise;
}


/**
* @desc Creates and return a new fetch API promise
* 
* @param {object} submit -- The submit object 
* @param {object} data   -- The data to submit
* @param {string} alert  -- The alert color 
* 
* @returns object
*/
export const fetchData = function (submit, data, action, method, alert="") {
    // Set a new promise
    const promise = new Promise((resolve, reject) => {
        let final = null;
        let submitText;

            // Check the submit selector
            if (submit) {
                submitText = submit.innerHTML;

                // Prepare the submit button
                submit.setAttribute("disabled", true);
                submit.innerHTML = constant("Requesting...");
            }

        // Submit data via fetch API
        fetch(action, {
            method: method,
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(result => {
            // Check the alert message
            if (alert !== null) {
                polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, alert, true, 750, 0);
            }

            // Everything is fine
            if (result.status === "success") {
                // Set the final result
                final = {
                    status: true,
                    data:   result
                }
            }

            // An error occoured
            else {
                // Set the final result
                final = {
                    status: false,
                    data:   result
                }
            }

                // Check the submit selector
                if (submit) {
                    // Reset the submit button
                    submit.removeAttribute("disabled");
                    submit.innerHTML = submitText;
                }
        })
        .then(() => {
            // Resolve the promise
            resolve(final);
        });
    });
    
    // Return the promise
    return promise;
}


/**
* @desc Deletes an item from a table
* 
* @var {HTMLElement|string} table   -- The table selector
* @var {string}             title   -- The delete unique title
* @var {string}             noItem  -- No item text
* @var {number}             colspan -- No table colspan
*/
export const deleteAPI = (table, title, noItem = 'No item found!', colspan = 1, fn = null) => {
    let parent;
    if (typeof(table) === 'string') parent = document.querySelector(table);
    else if (typeof(table) === 'object') parent = table;

    if (parent) {
        const tbody     = parent.querySelector('tbody');
        const selectors = parent.querySelectorAll(".delete-item");

        if (selectors.length > 0) {
            selectors.forEach((selector) => {
                selector.onclick = () => {
                    let id = selector.getAttribute("data-id");
                    let tr = parent.querySelector(`tr[data-id='${id}']`);

                    // Start delete process
                    fetchAPI(parent, selector, {delete_item: title, id: id})
                    .then((result) => {
                        // On success
                        if (result.status) {
                            // Remove the item from the table
                            polaris.remove(tr);
   
                            // Find trs length
                            const trs = tbody.querySelectorAll("tr");
   
                            // Check no item
                            if (trs.length == 0) {
                               // Append no item
                               polaris.append('tr', tbody, `<td class="text-center font-500" colspan="${colspan}">${noItem}</td>`, [], 'no-item');
                            }

                            // Invoke callback function
                            if (fn) fn();
                        }
                   });
               }
            });
        }
    }
};


/**
* @desc Checks app components (partials)
* 
* @param {string} app -- The app name 
*/
export const checkComponents = (app) => {
    // Page components
    // const components = spaComponents[app];

    // Check page components
    for (let x in components) {
        let node = document.querySelector(`.${components[x]}`);

        // Component not exists
        if (!node) {
            return false;
        }
    }

    // Everything is OK
    return true
};


/**
 * @desc Replace a node with HTML content
 * 
 * @param {*} selector 
 * @param {*} content 
 */
export const replaceHTML = (selector, content) => {
    let elem = document.createElement('div');
    elem.innerHTML = content;

    document.querySelector(selector).replaceWith(elem.childNodes[0]);
};


/**
 * Shows and hides nav
 */
export const toggleNav = (key = 'nav') => {
    if (document.querySelector(`#${key}`)) {
        // Show navigation
        if (document.querySelector(`#show-${key}`)) {
            // Check animation enter
            let animationEnter = "fadeInLeft";
            if (document.querySelector(`#${key}`).getAttribute("data-enter")) {
                animationEnter = document.querySelector(`#${key}`).getAttribute("data-enter");
            }
    
            // Set animation enter on click
            document.querySelector(`#show-${key}`).onclick = () => {
                polaris.animation(`#${key}`, animationEnter);
            }
        }
    
        // Hide nav
        if (document.querySelector(`#hide-${key}`)) {
            // Check animation exit
            let animationExit = "fadeOutLeft";
            if (document.querySelector(`#${key}`).getAttribute("data-exit")) {
                animationExit = document.querySelector(`#${key}`).getAttribute("data-exit");
            }
    
            // Set animation exit on click
            document.querySelector(`#hide-${key}`).onclick = () => {
                polaris.animation(`#${key}`, animationExit, true);
            }
        }
    
        // Toggle navigation
        if (document.querySelector(`#toggle-${key}`)) {
            // Check animation enter
            let animationEnter = "fadeInLeft";
            if (document.querySelector(`#${key}`).getAttribute("data-enter")) {
                animationEnter = document.querySelector(`#${key}`).getAttribute("data-enter");
            }
    
            // Check animation exit
            let animationExit = "fadeOutLeft";
            if (document.querySelector(`#${key}`).getAttribute("data-exit")) {
                animationExit = document.querySelector(`#${key}`).getAttribute("data-exit");
            }
    
            let navigation = document.querySelector(`#${key}`);
    
            // Toggle nav click
            document.querySelector(`#toggle-${key}`).onclick = () => {
                // Check close nav
                if (document.querySelector(`#${key}.close`)) {
                    // Hide nav
                    this.classList.remove("close");
                    polaris.animation(`#${key}`, animationExit, true).then(() => {
                        navigation.classList.remove("close");
                    });
                }
                else {
                    // Show nav
                    this.classList.add("close");
                    polaris.animation(`#${key}`, animationEnter).then(() => {
                        navigation.classList.add("close");
                    });
                }
            }
        }
    }
};


/**
 * Toggles window fullscrenn
 */
export const toggleScreen = () => {
    // Toggle screen
    const tgl = document.querySelector('#toggle-screen');
    if (tgl) {
        tgl.onclick = () => {
            const icon = tgl.querySelector('i');
            const elem = document.body;

            // Fullscreen
            if (!polaris.isFullscreen()) {
                polaris.fullscreen(elem);

                icon.classList.remove('fa-maximize');
                icon.classList.add('fa-minimize');
            }
            // Exit fullscreen
            else if (icon.classList.contains('fa-minimize')) {
                polaris.exitFullscreen();

                icon.classList.remove('fa-minimize');
                icon.classList.add('fa-maximize');
            }
        };
    }
};


// Capitalize text
export const capital = (text) => {
    return text[0].toUpperCase() + text.slice(1);
};


/**
 * Asynchronous render plugin API
 */
export const renderPlugins = (category) => {
    // Send API request
    fetch(dataAPI, {
        method: 'put',
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({render_plugins: category})
    });
}
