/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, constant} from "./core";


/**
 * Global variables
 */
let localStorage = window.localStorage;


/**
 * Files App APIs
 */
export const filesAPIs = () => {
    // Set filemanager height
    setTimeout(() => {
        filesHeight();
    }, 1000);

    // Upload file
    uploadFile();

    // Download file
    downloadFile();

    // New folder
    newFolder();

    // View item
    viewItem();

    // Rename item
    renameItem();

    // Item info
    itemInfo();

    // Copy URL
    copyUrl();

    // View mode
    viewMode();

    // Sort items
    sortItems();

    // Delete items
    deleteItems();

    // Handle select multi button
    selectMulti();

    // Handle files SPA links
    filesSPALink();

    // Handle items click
    itemsClick();

    // Handle items double click
    itemsDoubleClick();

    // Update filemanager buttons
    updateFilesButtons();
};


/**
 * Update filemanager buttons
 */
const updateFilesButtons = () => {
    // Update download button
    updateDownloadFile();

    // Update view item button
    updateViewItem();

    // Update item info button
    updateItemInfo();

    // Update item URL button
    updateCopyUrl();

    // Update rename item button
    updateRenameItem();

    // Update delete item button
    updateDeleteItem();
};


/**
 * Filemanager height
 */
const filesHeight = () => {
    // Find window size
    let windowHeight = window.innerHeight;
    if (windowHeight < 600) windowHeight = 600;

    // Find required partials' sizes
    let headerHeight = document.querySelector("#app-files .header").getBoundingClientRect().height;
    let upperHeight  = document.querySelector("#app-files .upper").getBoundingClientRect().height;
    let footerHeight = document.querySelector("#app-files .footer").getBoundingClientRect().height;

    // Nav & Main heights
    document.querySelector("#app-files .nav").style.height  = (windowHeight - (headerHeight + upperHeight + footerHeight)) + "px";
    document.querySelector("#app-files .main").style.height = (windowHeight - (headerHeight + upperHeight + footerHeight)) + "px";
    
    // Window resize handler
    window.onresize = () => {
        if (document.querySelector('#app-files')) filesHeight();
    };
};


/**
 * Handles files SPA links
 */
const filesSPALink = () => {
    const nodes = document.querySelectorAll('.files-spa-link');

    // Nodes listener on click
    if (nodes) {
        nodes.forEach(node => {
            node.onclick = () => {
                // Find folder info
                const folderId   = node.dataset.id;
                const folderPath = node.dataset.path;

                // Update folder info
                document.querySelector('#folder-info').innerHTML  = folderPath;
                document.querySelector('#folder-info').dataset.id = folderId;

                // Empty item info
                document.querySelector('#item-info').innerHTML = '';
                
                // Refresh items & folders
                refreshItems(folderId, true);

                // Prevent default behavior
                return false;
            };
        });
    }
};


/**
 * @desc Refresh items & folderdd
 * 
 * @param {*} folderId 
 * @param {*} refreshFolders 
 */
const refreshItems = (folderId=0, refreshFolders=false) => {
    // Page progress
    const pageLoader   = document.querySelector("#page-loader").querySelector(".progress");
    const pageProgress = document.querySelector("#page-loader").querySelector(".progress--bar");

    // Set the page progress
    polaris.animation(pageLoader, "fadeIn");
    polaris.animation(pageProgress, pageProgress.dataset.enter);

    // Required info
    const trail  = document.querySelector('#folder-trail');
    const action = trail.dataset.action;
    const method = 'put';

    // Fetch files list
    fetch(action, {
        method: method,
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            refresh_items:   true,
            refresh_folders: refreshFolders,
            folderId:        folderId
        })
    })
    .then(response => response.json())
    .then(result => {
        // Check result
        if (result.status == 'success') {
            // Refresh main content
            document.querySelector('.main .inner').innerHTML = result.items;

            // Update nav & header
            if (refreshFolders) {
                document.querySelector("#nav-folders").innerHTML  = result.folders;
                document.querySelector("#folder-trail").innerHTML = result.trail;
            }
        }

        // Unset the page progress
        polaris.animation(pageLoader, "fadeOut").then(() => {
            polaris.animation(pageProgress, "");
        });
    }).then(() => {
        // Handle items click
        itemsClick();

        // Handle items double click
        itemsDoubleClick();

        // Select multi item
        selectMulti();
        
        // Update filemanager buttons
        updateFilesButtons();

        // Handle files SPA links
        if (refreshFolders) {
            filesSPALink();
        }
    });
};


/**
 * Handles items click
 */
const itemsClick = () => {
    const selector = document.querySelector('#select-multi-btn');
    const nodes    = document.querySelectorAll('.files-items');

    // Nodes listener on click
    if (nodes) {
        nodes.forEach(node => {
            node.onclick = (e) => {
                // Check freezed item
                if (!node.classList.contains('freezed')) {
                    let itemPath = node.dataset.path;

                    // Single click
                    if (e.detail == 1) {
                        // Check selected file
                        if (node.classList.contains('selected')) {
                            node.classList.remove('selected');
                        }
                        else {
                            node.classList.add('selected');
                        }
                        
                        // Check select multi
                        if (!selector.classList.contains('active')) {
                            // Deselect all other files
                            nodes.forEach(elem => {
                                if (elem != node) elem.classList.remove('selected');
                            });
                        }
    
                        // Check selected count
                        if (document.querySelectorAll('.files-items.selected').length == 1) {
                            itemPath = document.querySelector('.files-items.selected').dataset.path;
    
                            // Update item info
                            document.querySelector('#item-info').innerHTML = itemPath;
                        }
                        else {
                            // Empty item info
                            document.querySelector('#item-info').innerHTML = '';
                        }
    
                        // Update filemanager buttons
                        updateFilesButtons();
                    }
                    
                    // Double click
                    else if (e.detail == 2) {
                        return false;
                    }
                }
            };
        });
    }
};


/**
 * Handles items double click
 */
const itemsDoubleClick = () => {
    const nodes = document.querySelectorAll('.files-items');

    // Nodes listener on double click
    if (nodes.length > 0) {
        nodes.forEach(node => {
            node.ondblclick = (e) => {
                // Check freezed item
                if (!node.classList.contains('freezed')) {
                    const itemPath = node.dataset.path;

                    // Deselect all items
                    nodes.forEach(item => {
                        item.classList.remove('selected');
                    });

                    // Select current item
                    node.classList.add('selected');

                    // Update item info
                    document.querySelector('#item-info').innerHTML = itemPath;

                    // Trigger view item button
                    document.querySelector('#view-item-btn').click();
    
                    // Update filemanager buttons
                    updateFilesButtons();
                }
            };
        });
    }
};


/**
 * Upload file
 */
const uploadFile = () => {
    const selector = document.querySelector('#upload-file-btn');
    if (selector) {
        // Handle icon click
        selector.onclick = () => {
            // Find upload data
            const data   = document.querySelector('#upload-file-data');
            const action = data.dataset.action;
            const method = data.dataset.method;
            const title  = data.dataset.title;
            const folder = document.querySelector('#folder-info').dataset.id;

            const uploadSettings = JSON.parse(localStorage.getItem("upload-settings"));
    
            // Modal body
            const body = `
            <form action="${action}" method="${method}" enctype="multipart/form-data" id="upload-file-form">
                <input name="folder-id" type="hidden" value="${folder}">
                ${data.innerHTML}
            </form>
            `
            // Create form modal
            polaris.modal(title, body, '', 'sm', 'zoomIn', 'zoomOut', '', true, true, 500);

            // New form
            const form = document.querySelector('#upload-file-form');

            // New form data
            const fileMain     = form.querySelector('.file-main');
            const fileBtn      = form.querySelector('.file-btn');
            const fileInput    = form.querySelector('.file-input');
            const fileUpload   = form.querySelector('.file-upload');
            const uploadResult = form.querySelector('.upload-result');

            // Global variables
            let files   = [];
            let names   = [];
            let exts    = [];
            let sizes   = [];
            let content = '';
        
            // Select Button
            fileBtn.onclick = () => {
                // Trigger file input click
                fileMain.click();
            };

            // Select input
            fileInput.onclick = () => {
                // Trigger file input click
                fileMain.click();
            };

            // File input onchange
            fileMain.onchange = () => {
                files = fileMain.files;
                names = [];
                exts  = [];
                sizes = [];

                // Find files name, size, and extension
                for (let i = 0; i < files.length; i++) {
                    names[i] = files[i].name; 
                    sizes[i] = files[i].size; 
                    exts[i]  = files[i].name.split('.')[files.length - 1];
                }
			
                // Update select input
                if (files.length == 0) fileInput.value = constant('No file chosen!');
                else 				   fileInput.value = names.join(', ');
            };

            // Upload buttom
            fileUpload.onclick = () => {
                let uploadError = false;

                // Reset the upload result
                uploadResult.innerHTML = '';

                // No file chosen
                if (files.length == 0) {
                    // Alert result message
                    polaris.alert(constant("No file selected!"), "fadeInTop", "fadeOutBottom", 'warning', "", true, 750, 0);
                }
                // File chosen
                else {
                    let fileName, fileSize, nameArr, fileExt;

                    // Loop files
                    for (let i = 0; i < files.length; i++) {
                        fileName = files[i].name; 
                        fileSize = files[i].size; 
                        nameArr  = fileName.split('.');
                        fileExt  = '.' + nameArr[nameArr.length - 1];

                        // Incorrect extension
                        if (!uploadSettings['upload_types'].includes(fileExt)) {
                            content  = `<p class="mb-sm font-lg color-danger">${fileName} (Extension error!)</p>`;
                            polaris.append('div', uploadResult, content);

                            uploadError = true;
                        }
                        // Correct extension
                        else {
                            // Image
                            if (uploadSettings['image_types'].includes(fileExt)) {
                                // File size error
                                if (fileSize > uploadSettings['image_size']) {
                                    content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
                                    polaris.append('div', uploadResult, content);
                                    uploadError = true;
                                }
                            }
                            // Audio
                            else if (uploadSettings['audio_types'].includes(fileExt)) {
                                // File size error
                                if (fileSize > uploadSettings['audio_size']) {
                                    content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
                                    polaris.append('div', uploadResult, content);
                                    uploadError = true;
                                }
                            }
                            // Video
                            else if (uploadSettings['video_types'].includes(fileExt)) {
                                // File size error
                                if (fileSize > uploadSettings['video_size']) {
                                    content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
                                    polaris.append('div', uploadResult, content);
                                    uploadError = true;
                                }
                            }
                            // Font
                            else if (uploadSettings['font_types'].includes(fileExt)) {
                                // File size error
                                if (fileSize > uploadSettings['font_size']) {
                                    content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
                                    polaris.append('div', uploadResult, content);
                                    uploadError = true;
                                }
                            }
                            // Text
                            else if (uploadSettings['text_type'].includes(fileExt)) {
                                // File size error
                                if (fileSize > uploadSettings['text_size']) {
                                    content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
                                    polaris.append('div', uploadResult, content);
                                    uploadError = true;
                                }
                            }
                            // PDF
                            else if (uploadSettings['pdf_type'].includes(fileExt)) {
                                // File size error
                                if (fileSize > uploadSettings['pdf_size']) {
                                    content  = `<p class="mb-sm font-lg color-danger">${fileName} (Size error!)</p>`;
                                    polaris.append('div', uploadResult, content);
                                    uploadError = true;
                                }
                            }
                        }
                    }

                    // Files are OK
                    if (!uploadError) {
                        // Submit the form
                        form.querySelector('.submit').click();
                    }
                }

                // Prevent default behavior
                return false;
            };

            // Form submition
            form.onsubmit = () => {
                // Reset the upload result
                uploadResult.innerHTML = '';

                // Disabled upload button
                fileUpload.setAttribute('disabled', true);

                // Append the progressbar
                content = `
                    <div class="progress mb-md" style="height: 1rem;">
                        <div class="progress--bar bg-notice color-white" style="width: 0%; transition: width 0.5s ease;">0%</div>
                    </div>
                `;
                polaris.append('div', uploadResult, content);

                // Progress data
                const progressBar = uploadResult.querySelector(`.progress--bar`);
                let   progress    = 0;

                // XMLHttp object
                const xhr = new XMLHttpRequest();

                // On ready state change
                xhr.onreadystatechange = function () {
                    this.upload.onprogress = e => {
                        progress = Math.floor((e.loaded / e.total) * 100);
                        
                        progressBar.style.width = `${progress}%`;
                        progressBar.innerHTML   = `${progress}%`;
                    };
                };

                // On load
                xhr.onload = function() {
                    let result = JSON.parse(this.responseText);

                    // Alert result message
                    polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, "", true, 750, 0);

                    // Enable upload button
                    fileUpload.removeAttribute('disabled');

                    // Success
                    if (result.status == 'success') {
                        polaris.toggleClass(progressBar, 'bg-notice', 'bg-success');
                    
                        // Update progress bar
                        progressBar.classList.remove('bg-notice');
                        progressBar.classList.remove('bg-danger');
                        progressBar.classList.add('bg-success');
                        progressBar.style.width = '100%';
                        progressBar.innerHTML   = '100%';

                        // Reset main input
                        fileMain.value = "";
                    
                        // Reset select input
                        fileInput.value = constant('No file chosen!');

                        // Refresh items
                        refreshItems(folder);

                    // Error
                    } else {
                        // Update progress bar
                        progressBar.classList.remove('bg-notice');
                        progressBar.classList.remove('bg-success');
                        progressBar.classList.add('bg-danger');
                    }
                };

                // Update form data
                let data   = new FormData(form);
                let method = form.getAttribute('method');
                let action = form.getAttribute('action');

                // Start upload process
                xhr.open(method, action, true);
                xhr.setRequestHeader("Accept", "application/json");
                xhr.setRequestHeader("enctype", "multipart/form-data");
                xhr.send(data);
                
                // Prevent default behavior
                return false;
            };

        };
    }
};


/**
 * New folder
 */
const newFolder = () => {
    const selector = document.querySelector('#new-folder-btn');
    if (selector) {

        // Handle on click
        selector.onclick = () => {
            // Find form data
            let   data   = document.querySelector('#new-folder-form');
            const action = data.dataset.action;
            const method = data.dataset.method;
            const title  = data.dataset.title;
            const folder = document.querySelector('#folder-info').dataset.id;
            const path   = document.querySelector('#folder-info').dataset.path;
    
            // Modal body
            const body = `
            <p><span class="font-500">${constant("Path")}:</span> <span class="dir-ltr">${path}</span></p>
            <form action="${action}" method="${method}">
                <input name="parent-id" type="hidden" value="${folder}">
                ${data.innerHTML}
            </form>
            `

            // Create form modal
            polaris.modal(title, body, '', 'xs', 'zoomIn', 'zoomOut', '', true, true, 500);

            // Find form element
            const form = document.querySelector('.modal form');

            // Form API
            form.onsubmit = () => {
                // Handle form submition
                submitForm(form).then(result => {
                    // On success
                    if (result.status) {
                        // Refresh items & folders
                        refreshItems(folder, true);

                        // Close the modal
                        document.querySelector('.modal .modal--close').click();
                    }
                });
    
                // Prevent default behavior
                return false;
            };

        };
    }
};


/**
 * Download file
 */
const downloadFile = () => {
    const selector = document.querySelector('#download-file-btn');
    if (selector) {
        selector.onclick = () => {
            // Check disabled
            if (!selector.classList.contains('disabled')) {
                // Fetch selected nodes
                const nodes = document.querySelectorAll('.files-items.selected');
            
                // Check nodes
                if (nodes) {
                    // Loop nodes
                    nodes.forEach(node => {
                        // Create a temporary link node
                        let tempNode = document.createElement('a');

                        // Update the temporary node
                        tempNode.style.display = 'none';
                        tempNode.setAttribute('href', node.dataset.path);
                        tempNode.setAttribute('download', true);
                        
                        // Trigger click on the temporary node
                        tempNode.click();

                        // Remove the temporary node
                        tempNode.remove();
                    });
                }
            }
        };
    }
};


/**
 * Handles select multi button
 */
const selectMulti = () => {
    let selector = document.querySelector('#select-multi-btn');
    const nodes  = document.querySelectorAll('.files-items');

    // Handle click
    if (selector) {
        selector.onclick = () => {
            // Check active
            if (selector.classList.contains('active')) {
                selector.classList.remove('active');
    
                // Deselect all files
                if (nodes) {
                    nodes.forEach(node => {
                        node.classList.remove('selected');
                    });
                }
    
                // Empty item info
                document.querySelector('#item-info').innerHTML = '';
            }
            else {
                selector.classList.add('active');
            }
    
            // Update filemanager buttons
            updateFilesButtons();
        };
    }
};


/**
 * View item
 */
const viewItem = () => {
    const selector = document.querySelector('#view-item-btn');
    if (selector) {
        selector.onclick = () => {
            if (!selector.classList.contains('disabled')) {
                // Fetch selected nodes
                const node = document.querySelector('.files-items.selected');
            
                // Check node
                if (node) {
                    // Find item type
                    let itemType;
                    if (node.classList.contains('item-file')) itemType = 'file';
                    else if (node.classList.contains('item-folder')) itemType = 'folder';

                    // File
                    if (itemType == 'file') {
                        // View file
                        window.open(node.dataset.path);
                    }
                    else if (itemType == 'folder') {
                        // Find folder info
                        const folderId   = node.dataset.id;
                        const folderPath = node.dataset.path;

                        // Update folder info
                        document.querySelector('#folder-info').innerHTML  = folderPath;
                        document.querySelector('#folder-info').dataset.id = folderId;
                        
                        // Refresh items & folders
                        refreshItems(node.dataset.id, true);
                    }
                    
                }
            }
        };
    }
};


/**
 * Rename item
 */
const renameItem = () => {
    const selector = document.querySelector('#rename-item-btn');

    if (selector) {
        selector.onclick = () => {
            const folderId = document.querySelector('#folder-info').dataset.id;

            // Check disabled
            if (!selector.classList.contains('disabled')) {
                // Fetch selected node
                const node = document.querySelector('.files-items.selected');
            
                // Check node
                if (node) {
                    const editable = node.querySelector('.editable');
                    let itemInfo   = node.dataset.path;
                    const oldName  = editable.innerHTML;
                    const itemId   = node.dataset.id;
                    let itemType;
    
                    // Find item type
                    if (node.classList.contains('item-file')) itemType = 'file';
                    else if (node.classList.contains('item-folder')) itemType = 'folder';

                    // Remove selected class
                    node.classList.remove('selected');

                    // Update filemanager buttons
                    updateFilesButtons();

                    // Empty item info
                    document.querySelector('#item-info').innerHTML = '';
                    
                    // Make node freezed
                    node.classList.add('freezed');

                    // Make node editable
                    editable.setAttribute('contenteditable', true);
                    editable.focus();

					// Keywboard key handler
					editable.onkeydown = event => {
						// Enter key handler
						if (event.keyCode == 13) {
							// Force blure
							editable.blur();
						}
					};

                    // Leave focus handler
                    editable.onblur = () => {
                        // Remove freezed class
                        node.classList.remove('freezed');

                        // Remove editable attribute
                        editable.removeAttribute('contenteditable');

                        // Required info
                        let itemName = editable.innerHTML;
                        const trail  = document.querySelector('#folder-trail');
                        const action = trail.dataset.action;
                        const method = 'put';

                        // Rename item API
                        fetch(action, {
                            method: method,
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            }),
                            body: JSON.stringify({
                                rename_item: true,
                                itemId:      itemId,
                                itemType:    itemType,
                                itemName:    itemName,
                                folderId:    folderId
                            })
                        })
                        .then(response => response.json())
                        .then(result => {
                            // Alert result message
                            polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, alert, true, 750, 0);

                            // On success
                            if (result.status == 'success') {
                                // File
                                if (itemType == 'file') {
                                    // Refresh items
                                    refreshItems(folderId);
                                }
                                // Folder
                                else if (itemType == 'folder') {
                                    // Refresh items & folders
                                    refreshItems(folderId, true);
                                }
                            }
                            // On error
                            else {
                                // Reset the name
                                editable.innerHTML = oldName;
                            }
                        });

                    };
                }
            }
        };
    }
};


/**
 * Item info
 */
const itemInfo = () => {
    const selector = document.querySelector('#item-info-btn');

    if (selector) {
        selector.onclick = () => {
            // Check disabled
            if (!selector.classList.contains('disabled')) {
                // Fetch selected node
                const node = document.querySelector('.files-items.selected');
            
                // Check node
                if (node) {
                    const title   = constant("Item Info");
                    const loading = constant("Loading...");

                    // Create info modal
                    polaris.modal(title, loading, '', 'sm', 'zoomIn', 'zoomOut', '', true, true, 500);

                    // Required info
                    const trail  = document.querySelector('#folder-trail');
                    const action = trail.dataset.action;
                    const method = 'put';

                    // Item ID
                    const itemId = node.dataset.id;
    
                    // Find item type
                    let itemType;
                    if (node.classList.contains('item-file')) itemType = 'file';
                    else if (node.classList.contains('item-folder')) itemType = 'folder';

                    // Item info API
                    fetch(action, {
                        method: method,
                        headers: new Headers({
                            'Content-Type': 'application/json'
                        }),
                        body: JSON.stringify({
                            item_info: true,
                            itemId:    itemId,
                            itemType:  itemType
                        })
                    })
                    .then(response => response.json())
                    .then(result => {
                        // On success
                        if (result.status == 'success') {
                            // Update modal body
				            document.querySelector(".modal--body").innerHTML = result.info;
                        }
                        // On error
                        else {
                            // Alert result message
                            polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, alert, true, 750, 0);
                        }
                    });
                }
            }
        };
    }
};


/**
 * Copy URL
 */
const copyUrl = () => {
    const selector = document.querySelector('#copy-url-btn');

    if (selector) {
        selector.onclick = () => {
            // Check disabled
            if (!selector.classList.contains('disabled')) {
                // Fetch selected node
                const node = document.querySelector('.files-items.selected');
            
                // Check node
                if (node) {
                    // Find item URL
                    const itemUrl = node.dataset.path;

                    // Copy URL to clipboard
                    try {
                        polaris.copy(itemUrl);

                        // On success
                        polaris.alert(constant('URL copied to clipboard!'), "fadeInTop", "fadeOutBottom", 'success', "", true, 750, 0);
                    }
                    catch {
                        // On error
                        polaris.alert(constant('Copy failed!'), "fadeInTop", "fadeOutBottom", 'warning', "", true, 750, 0);
                    }

                }
            }
        };
    }
};


/**
 * View mode (list & grid)
 */
const viewMode = () => {
    const nodes = document.querySelectorAll('#view-mode-btn a');

    // Nodes listener on click
    if (nodes.length > 0) {
        nodes.forEach(node => {
            node.onclick = () => {
                // Activate the node
                node.classList.add('active');

                // Remove other nodes active class
                nodes.forEach(elem => {
                    if (elem != node) {
                        elem.classList.remove('active');
                    }
                });

                // Find mode
                const mode     = node.dataset.mode;
                const folderId = document.querySelector('#folder-info').dataset.id;

                // Required info
                const trail  = document.querySelector('#folder-trail');
                const action = trail.dataset.action;
                const method = 'put';

                // View mode API
                fetch(action, {
                    method: method,
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({
                        view_mode: true,
                        mode:      mode
                    })
                })
                .then(response => response.json())
                .then(result => {
                    // Check result
                    if (result.status == 'success') {
                        // Refresh items & folders
                        refreshItems(folderId, true);
                    }
                });

                // Prevent default behavior
                return false;
            };
        });
    }
};


/**
 * Sort items (Name, Type, Size, Date)
 */
const sortItems = () => {
    const sorts = document.querySelectorAll('#sort-items-btn .ul--first a');
    const tones = document.querySelectorAll('#sort-items-btn .ul--second a');

    // Sorts listener on click
    if (sorts.length > 0) {
        sorts.forEach(node => {
            node.onclick = () => {
                // Activate the node
                node.classList.add('active');

                // Remove other sorts active class
                sorts.forEach(elem => {
                    if (elem != node) {
                        elem.classList.remove('active');
                    }
                });

                // Find sort
                const sort     = node.dataset.sort;
                const folderId = document.querySelector('#folder-info').dataset.id;

                // Required info
                const trail  = document.querySelector('#folder-trail');
                const action = trail.dataset.action;
                const method = 'put';

                // View mode API
                fetch(action, {
                    method: method,
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({
                        sort_items: true,
                        sort:       sort,
                        tone:       false
                    })
                })
                .then(response => response.json())
                .then(result => {
                    // Check result
                    if (result.status == 'success') {
                        // Refresh items & folders
                        refreshItems(folderId, true);
                    }
                });

                // Prevent default behavior
                return false;
            };
        });
    }

    // Tones listener on click
    if (tones.length > 0) {
        tones.forEach(node => {
            node.onclick = () => {
                // Activate the node
                node.classList.add('active');

                // Remove other tones active class
                tones.forEach(elem => {
                    if (elem != node) {
                        elem.classList.remove('active');
                    }
                });

                // Find tone
                const tone     = node.dataset.tone;
                const folderId = document.querySelector('#folder-info').dataset.id;

                // Required info
                const trail  = document.querySelector('#folder-trail');
                const action = trail.dataset.action;
                const method = 'put';

                // View mode API
                fetch(action, {
                    method: method,
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({
                        sort_items: true,
                        tone:       tone,
                        sort:       false
                    })
                })
                .then(response => response.json())
                .then(result => {
                    // Check result
                    if (result.status == 'success') {
                        // Refresh items & folders
                        refreshItems(folderId, true);
                    }
                });

                // Prevent default behavior
                return false;
            };
        });
    }
};


/**
 * Delete items
 */
const deleteItems = () => {
    const selector = document.querySelector('#delete-item-btn .delete-item');

    if (selector) {
        selector.onclick = () => {
            // Check popup disabled
            if (!document.querySelector('#delete-item-btn').classList.contains('disabled')) {
                // Required info
                const folderId = document.querySelector('#folder-info').dataset.id;
                const nodes    = document.querySelectorAll('.files-items.selected');

                // Check nodes
                if (nodes.length > 0) {
                    // Delete blueprints
                    let items = {};
                    let id, type;
                    let types = [];
                    
                    // Fetch items info
                    nodes.forEach(node => {
                        id = node.dataset.id;
        
                        // Folder
                        if (node.classList.contains('item-folder')) {
                            type = "folder";
                            types.push(type);
                        }
                        // File
                        else if (node.classList.contains('item-file')) {
                            type = "file";
                            types.push(type);
                        }

                        // Update items object
                        items[id] = type;
                    });
    
                    // Prepare the submit button
                    const submitText = selector.innerHTML;
                    selector.setAttribute("disabled", true);
                    selector.innerHTML = constant("Requesting...");

                    // Find action & method
                    const action = document.querySelector('#folder-trail').dataset.action;
                    const method = 'delete';

                    // Delete item API
                    fetch(action, {
                        method: method,
                        headers: new Headers({
                            'Content-Type': 'application/json'
                        }),
                        body: JSON.stringify({
                            delete_items: true,
                            items:        items,
                            folderId:     folderId
                        })
                    })
                    .then(response => response.json())
                    .then(result => {
                        // Alert result message
                        polaris.alert(result.message, "fadeInTop", "fadeOutBottom", result.status, alert, true, 750, 0);
    
                        // On success
                        if (result.status == 'success') {
                            // Include folder(s)
                            if (types.includes('folder')) {
                                // Refresh items & folders
                                refreshItems(folderId, true);
                            }
                            // Only file(s)
                            else {
                                // Refresh items
                                refreshItems(folderId);
                            }
    
                            // Empty item info
                            document.querySelector('#item-info').innerHTML = '';
    
                            // Close popup
                            document.querySelector('#delete-item-btn .popup--close').click();
                        }
    
                        // Reset the submit button
                        selector.removeAttribute("disabled");
                        selector.innerHTML = submitText;
                    });
                }
            }
            else {
                // Close popup
                document.querySelector('#delete-item-btn .popup--close').click();
            }
        };
    }
};


/**
 * Updates download file button
 */
const updateDownloadFile = () => {
    const downloadBtn = document.querySelector('#download-file-btn');
    const folders     = document.querySelectorAll('.item-folder.selected');
    const files       = document.querySelectorAll('.item-file.selected');

    if (downloadBtn) {
        // Check selected folders
        if (folders.length > 0) {
            downloadBtn.classList.add('disabled');
        } else {
            if (files.length > 0) {
                downloadBtn.classList.remove('disabled');
            }
            else {
                downloadBtn.classList.add('disabled');
            }
        }
    }
};


/**
 * Updates view item button
 */
const updateViewItem = () => {
    const viewBtn = document.querySelector('#view-item-btn');
    const folders = document.querySelectorAll('.item-folder.selected');
    const files   = document.querySelectorAll('.item-file.selected');

    if (viewBtn) {
        // Check selected folders & files
        if (folders.length + files.length == 1) {
            viewBtn.classList.remove('disabled');
        } else {
            viewBtn.classList.add('disabled');
        }
    }
};


/**
 * Updates item info button
 */
const updateItemInfo = () => {
    const infoBtn = document.querySelector('#item-info-btn');
    const folders = document.querySelectorAll('.item-folder.selected');
    const files   = document.querySelectorAll('.item-file.selected');

    if (infoBtn) {
        // Check selected folders & files
        if (folders.length + files.length == 1) {
            infoBtn.classList.remove('disabled');
        } else {
            infoBtn.classList.add('disabled');
        }
    }
};


/**
 * Updates copy URL button
 */
const updateCopyUrl = () => {
    const pathBtn = document.querySelector('#copy-url-btn');
    const folders = document.querySelectorAll('.item-folder.selected');
    const files   = document.querySelectorAll('.item-file.selected');

    if (pathBtn) {
        // Check selected folders & files
        if (folders.length + files.length == 1) {
            pathBtn.classList.remove('disabled');
        } else {
            pathBtn.classList.add('disabled');
        }
    }
};


/**
 * Updates rename item button
 */
const updateRenameItem = () => {
    const renameBtn = document.querySelector('#rename-item-btn');
    const folders   = document.querySelectorAll('.item-folder.selected');
    const files     = document.querySelectorAll('.item-file.selected');

    if (renameBtn) {
        // Check selected folders & files
        if (folders.length + files.length == 1) {
            renameBtn.classList.remove('disabled');
        } else {
            renameBtn.classList.add('disabled');
        }
    }
};


/**
 * Updates delete item button
 */
const updateDeleteItem = () => {
    const deleteBtn = document.querySelector('#delete-item-btn');
    const folders   = document.querySelectorAll('.item-folder.selected');
    const files     = document.querySelectorAll('.item-file.selected');

    if (deleteBtn) {
        // Single File deletion
        // // Check selected folders & files
        // if (folders.length + files.length == 1) {
        //     deleteBtn.classList.remove('disabled');
        // } else {
        //     deleteBtn.classList.add('disabled');
        // }
        // Multi file deletion
        // Check selected folders & files
        if (folders.length + files.length == 0) {
            deleteBtn.classList.add('disabled');
        } else {
            deleteBtn.classList.remove('disabled');
        }
    }
};
