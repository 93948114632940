/**
 * Dependencies
 */
import {polaris} from "./config";
import {submitForm, constant} from "./core";


/**
 * Global variables
 */
let localStorage = window.localStorage;


/**
 * Profile App APIs
 */
export const profileAPIs = () => {
    // Change bio
    changeBio();

    // Profile photo
    profilePhoto();

    // Change Password
    changePassword();

    // Profile charts
    profileCharts();
};


/**
 * Change bio
 */
const changeBio = () => {
    const form = document.querySelector('#bio-form');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form)
            .then((result) => {
                // On success
                if (result.status) {
                    // Update the profile in nav
                    document.querySelector("#profile-name").innerHTML = `${result.data.fname} ${result.data.lname}`;

                    document.querySelector("#profile-bio").classList.remove('display-none');
                    document.querySelector("#profile-bio").innerHTML = result.data.bio;
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Profile photo
 */
const profilePhoto = () => {
    const form = document.querySelector("#profile-photo-form");
    if (form) {
        const selectPhoto  = document.querySelector("#profile-photo-change");
        const userPhoto    = document.querySelector("#profile-photo");
        const photoInput   = document.querySelector("input[name=photo]");
        const cancelUpload = document.querySelector("#profile-photo-cancel");
    
        // Select photo
        selectPhoto.onclick = () => {
            localStorage.setItem("userPhoto", userPhoto.getAttribute("src"));
        
            let newPhoto = localStorage.getItem("userPhoto");
    
            // Trigger file input click
            photoInput.click();
    
            // Cancel button
            cancelUpload.onclick = () => {
                userPhoto.src = localStorage.getItem("userPhoto");
                form.classList.add("display-none");
                selectPhoto.classList.remove("display-none");
                photoInput.value = "";
            };
        
            // Handle photo input change
            photoInput.onchange = function () {
                newPhoto = window.URL.createObjectURL(this.files[0]);
                userPhoto.src = newPhoto;
        
                if (newPhoto != localStorage.getItem("userPhoto")) {
                    selectPhoto.classList.add("display-none");
                    form.classList.remove("display-none");
                }
            };
    
            // Handle form submit
            form.onsubmit = () => {
                // Disable the cancel button
                cancelUpload.setAttribute("disabled", true);
    
                // Handle form submition
                submitForm(form)
                .then((result) => {
                    // On success
                    if (result.status) {
                        // Reset the buttons
                        selectPhoto.classList.remove("display-none");
                        form.classList.add("display-none");
    
                        // Rest the photo input
                        photoInput.value = "";
                    }
    
                    // Enable the cancel button
                    cancelUpload.removeAttribute("disabled");
                });
    
                // Prevent default behavior
                return false;
            };
        };
    
    }
};


/**
 * Change password
 */
const changePassword = () => {
    const form = document.querySelector('#change-password-form');
    if (form) {
        form.onsubmit = () => {
            // Handle form submition
            submitForm(form, true)
            .then((result) => {
                // On success
                if (result.status) {
                    // Hide the password error message
                    document.querySelector("#password-error").classList.add("display-none");

                    // Redirect to `url` after 1 second
                    setTimeout(function() {
                        polaris.redirect(result.data.url);
                    }, 2000);

                    // Update the dynamic link
                    // let link = document.querySelector("#dynamic-link");
                    // link.setAttribute('href', result.data.url);

                    // // Trigger the click event
                    // link.click();
                }
                // On error
                else {
                    // Password error message
                    if (result.data.password_error) {
                        document.querySelector("#password-error").classList.remove("display-none");
                    } 
                }
            });

            // Prevent default behavior
            return false;
        };
    }
};


/**
 * Profile charts
 */
const profileCharts = () => {
	// Activities & Visits Chart
	const activitiesVisitsChart = document.querySelector('#activities-visits-chart');
	if (activitiesVisitsChart) {
        let doc = document.querySelector('.doc');
        let action = activitiesVisitsChart.dataset.action;

		// Activities & Visits API
		fetch(action, {
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify({
				activities_visits: true
			})
		})
        .then(response => response.json())
        .then(result => {
			// Check result
			if (result.status == 'success') {
				let labels     = eval(result.labels);
				let visits     = eval(result.visits);
                let activities = eval(result.activities); 
                let title, datasets;

                // Check activities
                if (activities) {
                    title = result.const_activities_visits;

                    datasets = [
                        {
                            label:           result.const_my_activities,
                            data:            activities,        // [23,43,45,34,54,19,45]
                            backgroundColor: 'rgba(66, 131, 242, 0.87)',
                            borderColor:     'rgba(66, 131, 242, 0.5)'
                        },
                        {
                            label:           result.const_my_visits,
                            data:            visits,            // [39,57,23,61,70,28,27]
                            backgroundColor: 'rgba(242, 56, 56, 0.87)',
                            borderColor:     'rgba(242, 56, 56, 0.5)'
                        }
                    ]
                }
                else {
                    title = 'Visits';

                    datasets = [
                        {
                            label:           'My Visits',
                            data:            visits,            // [39,57,23,61,70,28,27]
                            backgroundColor: 'rgba(242, 56, 56, 0.87)',
                            borderColor:     'rgba(242, 56, 56, 0.5)'
                        }
                    ]
                }

                // Instantiate the Chart class
                const chart = new Chart(activitiesVisitsChart.getContext('2d'), {
                    type:    'line',     // line, bar, pie, doughnut, etc'
                    data:    {
                        labels:   labels,                           // ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                        datasets: datasets
                    },
                    options: {
                        scales: {
                            x: {
                                grid: {
                                    color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
                                },
                                ticks: {
                                    color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                }
                            },
                            y: {
                                grid: {
                                    color: () => (doc.classList.contains('doc-light')) ? '#DBDBDB' : '#595959',
                                },
                                ticks: {
                                    color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                labels: {
                                    color: () => (doc.classList.contains('doc-light')) ? '#747474' : '#A8A8A8',
                                }
                            },
                            title: {
                                display: true,
                                text:    title,
                                color: () => (doc.classList.contains('doc-light')) ? '#595959' : '#C1C1C1',
                                font: {
                                    size:   16,
                                    weight: 300
                                }
                            }
                        }
                    }
                });
			}
		});
	}
};
